.landing-header {
    font-family: sans-serif;
    font-weight: 100;
    font-size: clamp(8pt, 5vw, 12pt);
    color: white;
    /* transition: opacity 2s; */
}

.nav-logo {
    height: 20px;
    cursor: pointer;
    position: relative;
    z-index: 9999;
    padding-left: 7px;
}

.Nav-ul {
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
}

.Nav-li {
    display: block;
    flex: 0 1 auto; /* Default */
}

.nav-text {
    font-family: sans-serif;
    font-size: clamp(8pt, 5vw, 12pt);
    font-weight: 100;
    color: white;
    cursor: pointer;

    display: inline-block;
    position: relative;
}

.nav-text:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: white;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  .nav-text:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  .nav-text-active {
    border-bottom: solid 1px white;
  }

  .hamburger-text {
    color: white;
    font-size: 20pt;
  }

  .hamburger-text:hover {
    text-decoration: none;
    color: grey;
  }